<template>
	<div>
		<el-dialog :draggable="true" width="40%" @close="Reset" :destroy-on-close="true" v-model="outerVisible"
			title="现金新增">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="党员类型">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.type" placeholder="请选择党员类型">
							<el-option label="全职党员" :value="0"></el-option>
							<el-option label="离退休干部职工党员" :value="1"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="选择党员">
						<span class="xx">*</span>
						<el-select clearable filterable v-model="uid" @change="change" placeholder="请选择党员(可搜索)">
							<el-option v-for="(item,index) in list" :key="index" :label="item.name" :value="index">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="姓名" v-if="ruleForm.uid">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<el-form-item label="手机号码" v-if="ruleForm.uid">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.tel" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="身份证" v-if="ruleForm.uid">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.idcard" placeholder="请输入身份证"></el-input>
					</el-form-item>
					<el-form-item label="性别" v-if="ruleForm.uid">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.sex" placeholder="请选择性别">
							<el-option label="男" value="男"></el-option>
							<el-option label="女" :value="女"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="缴费金额" v-if="ruleForm.uid">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.price" placeholder="请输入缴费金额"></el-input>
						<p class="config" @click="$router.push({path:'/clique'})">缴费金额配置>></p>
					</el-form-item>
					<el-form-item label="所从事行业" v-if="ruleForm.uid">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.position" placeholder="请输入所从事行业"></el-input>
					</el-form-item>
					<el-form-item label="状态">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.status" placeholder="请选择状态">
							<el-option label="未支付" :value="0"></el-option>
							<el-option label="已支付" :value="1"></el-option>
							<el-option label="退款中" :value="2"></el-option>
							<el-option label="退款失败" :value="3"></el-option>
							<el-option label="退款成功" :value="4"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/partyOrder";

	export default {
		data() {
			return {
				title: '',
				outerVisible: false,
				ruleForm: {
					name: '',
					tel: '',
					idcard: '',
					sex: '',
					position: '',
					type: 0,
					price: "",
					uid: '',
					status: 1
				},
				list: [],
				uid: ''
			}
		},
		methods: {
			// 选择党员
			change(e) {
				let data = this.list[e]
				this.ruleForm.uid = data.id
				this.ruleForm.name = data.name
				this.ruleForm.tel = data.tel
				this.ruleForm.idcard = data.idcard
				this.ruleForm.sex = data.sex
				this.ruleForm.price = data.party_fee
				this.ruleForm.position = data.industry
			},
			// 获取党员
			getList() {
				this.$http.post(api.dylist, {
						page: 1,
						limit: 9999,
						identity: 3
					})
					.then((res) => {
						if (res.code == 200) {
							this.list = res.data.data
						}
					})
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				this.uid = ""
				this.ruleForm.status = 1
			},
			//新增/编辑
			SubmitForm() {
				if (this.ruleForm.type === '') {
					ElMessage({
						message: '请选择党员类型',
						type: 'warning',
					})
				} else if (this.ruleForm.uid === '') {
					ElMessage({
						message: '请选择党员',
						type: 'warning',
					})
				} else if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入姓名',
						type: 'warning',
					})
				} else if (this.ruleForm.tel === '') {
					ElMessage({
						message: '请输入手机号码',
						type: 'warning',
					})
				} else if (!(/^1[3456789]\d{9}$/.test(this.ruleForm.tel))) {
					ElMessage({
						message: '请输入正确的号码格式',
						type: 'warning',
					})
				} else if (this.ruleForm.idcard === '') {
					ElMessage({
						message: '请输入身份证号码',
						type: 'warning',
					})
				} else if (!(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(this.ruleForm.idcard))) {
					ElMessage({
						message: '请输入正确身份证号码格式',
						type: 'warning',
					})
				} else if (this.ruleForm.sex == "") {
					ElMessage({
						message: '请选择性别',
						type: 'warning',
					})
				} else if (Number(this.ruleForm.price) == "" || Number(this.ruleForm.price) <= 0) {
					ElMessage({
						message: '请输入缴费金额',
						type: 'warning',
					})
				} else {
					this.add()
				}

			},
			// 新增
			add() {
				this.$http.post(api.create, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			},
			// 显示隐藏
			show() {
				this.getList()
				this.outerVisible = true
			}
		}
	}
</script>

<style scoped>
	.config{
		font-size: 12px;
		text-align: center;
		width: 150px;
		cursor: pointer;
		color: #00ad79;
	}
</style>
