import request from './request'

const api = {
	// 获取列表
	lists: '/partyfeeorder/lists',
	// 添加
	add: '/partyfeeorder/add',
	// 删除
	dele: '/partyfeeorder/del',
	// 编辑
	edit: '/partyfeeorder/update',
	// 详情
	info: '/partyfeeorder/read',
	cunlist: '/village/lists',
	// 获取统计接口
	statistics: '/system/statistics',
	// 党员
	dylist: '/partymember/lists',
	// 添加党费缴费订单
	create: '/partyfeeorder/create',
}

export {
	request,
	api
}
