<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li @click="$router.go(-1)" v-if="name">
					<el-button size="mini"><i class="icon iconfont icon-fanhui"></i>返回上一步</el-button>
				</li>
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-if="$store.state.villageId>0">
					<el-button @click="bindfrom" color="#00ad79" class="el-white" style="color: white" size="mini">现金新增
					</el-button>
				</li>
				<li v-if="$store.state.villageId>0" v-permission="['partyOrderdele']">
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.keyword" placeholder="请输入名称">
					</el-input>
				</li>
				<li v-if="$store.state.villageId==0">
					<el-select clearable filterable v-model="form.village_id" placeholder="请选择村">
						<el-option v-for="item in cunlist" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</li>
				<li>
					<el-select clearable v-model="form.status" placeholder="请选择状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="已付款" :value="1"></el-option>
						<el-option label="退款中" :value="2"></el-option>
						<el-option label="退款失败" :value="3"></el-option>
						<el-option label="退款成功" :value="4"></el-option>
					</el-select>
				</li>
				<li>
					<el-select clearable v-model="form.type" placeholder="请选择党费类型">
						<el-option label="全部" value=""></el-option>
						<el-option label="全职党员" :value="0"></el-option>
						<el-option label="离退休干部职工党员" :value="1"></el-option>
					</el-select>
				</li>
				<li>
					<el-date-picker @change="change" v-model="dates" type="datetimerange" range-separator="-"
						start-placeholder="请选择开始时间" end-placeholder="请选择结束时间">
					</el-date-picker>
				</li>
				<li>
					<el-button @click="search" color="#00ad79" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" width="80" />
				<el-table-column label="党费类型">
					<template #default="scope">
						<span v-if="scope.row.type==0">全职党员</span>
						<span v-if="scope.row.type==1">离退休干部职工党员</span>
					</template>
				</el-table-column>
				<el-table-column property="village_name" label="村名称" />
				<el-table-column property="name" label="姓名" />
				<el-table-column property="sex" label="性别" />
				<el-table-column property="tel" label="手机号码" />
				<el-table-column property="idcard" label="身份证号码" width="120" />
				<el-table-column property="position" label="所从事行业" width="120" />
				<!-- <el-table-column property="wages" label="党员工资" /> -->
				<el-table-column property="price" label="缴费金额" />
				<el-table-column property="repair_price" label="补缴金额" />
				<el-table-column label="状态">
					<template #default="scope">
						<span v-if="scope.row.status==0">待付款</span>
						<span v-if="scope.row.status==1">已付款</span>
						<span v-if="scope.row.status==2">退款中</span>
						<span v-if="scope.row.status==3">退款失败</span>
						<span v-if="scope.row.status==4">退款成功</span>
					</template>
				</el-table-column>
				<el-table-column property="reason" label="失败原因" />
				<el-table-column property="create_time" label="创建时间" />
				<el-table-column fixed="right" property="address" label="操作" width="300" v-if="$store.state.villageId>0">
					<template #default="scope">
						<el-button v-if="scope.row.status==2" v-permission="['partyOrderagree']" size="mini"
							@click="edits(scope.row.id)">同意退款</el-button>
						<el-button v-if="scope.row.status==2" v-permission="['partyOrderrefuse']" size="mini"
							@click="cause(scope.row.id)">拒绝退款</el-button>
						<el-button v-permission="['partyOrderdele']" size="mini"
							@click="dele(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<div class="pagesTotal">
					已缴费总计(付款):<span>￥{{paid_price}}</span>
				</div>
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 退款原因 -->
		<cause ref="cause" @SubmitForm="getList"></cause>
		<!-- 新增 -->
		<forms ref="form" @SubmitForm="getList"></forms>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/partyOrder";
	import cause from './components/cause.vue'
	import forms from './components/form.vue'
	export default {
		components: {
			cause,
			forms
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				total: 0,
				form: {
					keyword: '',
					page: 1,
					limit: 15,
					status: 1,
					village_id: '',
					type: '',
					start_time: '',
					end_time: ''
				},
				tableData: [],
				idlist: [],
				cunlist: [],
				dates: [],
				paid_price:0,
				name:''
			}
		},
		created() {
			if (this.$route.query.state) {
				this.form.status = Number(this.$route.query.state)
				this.getList()
			}
			if (this.$route.query.name) {
				this.name = this.$route.query.name
				this.form.keyword = this.$route.query.name
				this.getList()
			}
			this.getList()
			this.getcun()
		},
		methods: {
			// 新增
			bindfrom() {
				this.$refs.form.show()
			},
			// 选择时间
			change(e) {
				if (e) {
					this.form.start_time = this.$Moment(e[0]).format('YYYY/MM/DD HH:mm:ss')
					this.form.end_time = this.$Moment(e[1]).format('YYYY/MM/DD HH:mm:ss')
				} else {
					this.form.start_time = ""
					this.form.end_time = ""
				}
			},
			
			// 获取列表
			getcun() {
				this.$http.post(api.cunlist, {
						page: 1,
						limit: 999,
					})
					.then((res) => {
						if (res.code == 200) {
							this.cunlist = res.data.data
						}
					})
			},
			// 同意
			edits(id) {
				ElMessageBox.confirm(
						'是否确定同意退款',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.edit, {
								id: id,
								status: 4
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '退款成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
			},
			// 原因
			cause(id) {
				this.$refs.cause.show(id)
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.dates = []
				this.form.status = 1
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.data
							this.total = res.data.count
							this.paid_price = res.data.paid_price
							this.tableloading = false
						}
					})
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								id: id
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style scoped>
</style>